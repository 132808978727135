import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/personal-site/personal-site/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Gatsby`}</li>
      <li parentName="ul">{`typography.js`}</li>
      <li parentName="ul">{`tailwind`}</li>
      <li parentName="ul">{`React`}</li>
      <li parentName="ul">{`mdx`}</li>
      <li parentName="ul">{`gh-pages`}</li>
      <li parentName="ul">{`github actions`}</li>
      <li parentName="ul">{`prismjs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      